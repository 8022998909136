import React from "react"
import { graphql } from "gatsby"

import EventTitle from "../components/eventTitle"
import Layout from "../components/layout"
import Link from "../components/link"
import Snippet from "../components/snippet"
import MarkdownAsHtml from "../infra/markdownAsHtml"

import { timeSlotText, timeSlotUtcText, trackText } from "../infra/functions"

import json from "../../content/meta/schedule.json"

import style from "./schedule.module.css"

const SchedulePage = ({ data }) => (
	<Layout
		meta={{
			title: "Schedule",
			description:
				"18 talks on Java, web dev, and operations from Loom and Graal to Javascript engines to Kubernetes, from Spring to React to Azure.",
			keywords: "Java conference Karlsruhe",
			path: "/",
		}}
		wide
	>
		<Snippet id="schedule-intro" />
		{schedule(data.talks.nodes, data.interactives.nodes, json.blocks)}
		<Snippet id="schedule-outro" />
	</Layout>
)

const schedule = (talks, interactives, blocks) => (
	<div>
		<Snippet id="schedule-table-intro" />
		<div className={style.schedule}>
			{blocks.map(block => scheduleBlock(talks, interactives, block))}
		</div>
	</div>
)

const scheduleBlock = (talks, interactives, block) => {
	const blockTalks = block.talk ? [block.talk] : block.talks ? block.talks : undefined
	const blockEvents = block.event ? [block.event] : block.events ? block.events : undefined
	const time = { start: block.start, end: block.end }
	return (
		<div className={style.block}>
			<div className={style.blockHeader}>
				<span>{timeSlotText(time.start, time.end)}</span>
				<span className={style.blockTitle}>{block.title}</span>
			</div>
			{block.description && <p className={style.blockDescription}>{block.description}</p>}
			{blockTalks && (
				<div className={style.events}>
					{blockTalks.map(blockTalk => scheduleTalk(talks, blockTalk))}
				</div>
			)}
			{blockEvents && scheduleIntermissions(talks, interactives, blockEvents)}
		</div>
	)
}

const scheduleIntermissions = (talks, interactives, events) => {
	if (events.length <= 3)
		return (
			<div className={style.events}>
				{events.map(event => scheduleEvent(talks, interactives, event))}
			</div>
		)

	if (events.length === 4)
		return (
			<React.Fragment>
				<div className={style.events}>
					{events.slice(0, 2).map(event => scheduleEvent(talks, interactives, event))}
				</div>
				<div className={style.events}>
					{events.slice(2).map(event => scheduleEvent(talks, interactives, event))}
				</div>
			</React.Fragment>
		)

	return (
		<React.Fragment>
			<div className={style.events}>
				{events.slice(0, 3).map(event => scheduleEvent(talks, interactives, event))}
			</div>
			<div className={style.events}>
				{events.slice(3).map(event => scheduleEvent(talks, interactives, event))}
			</div>
		</React.Fragment>
	)
}

const scheduleEvent = (talks, interactives, event) => {
	const time = { start: event.start, end: event.end }
	if (event.interactive) return scheduleInteractive(interactives, event.interactive, time)
	if (event.talk) return scheduleTalk(talks, event.talk, time)
	if (event.intermission) return scheduleIntermission(event)
}

const scheduleTalk = (talks, slug, time) => {
	const event = talks.find(event => event.frontmatter.slug === slug)
	if (!event) throw new Error(`Could not find talk ${slug}.`)
	return scheduleTalkOrInteractive(event, time)
}

const scheduleInteractive = (interactives, slug, time) => {
	const event = interactives.find(event => event.frontmatter.slug === slug)
	if (!event) throw new Error(`Could not find interactive ${slug}.`)
	return scheduleTalkOrInteractive(event, time, style.interactive)
}

const scheduleTalkOrInteractive = (event, time, className) => {
	if (!className) className = ""
	return (
		<div id={event.frontmatter.slug} className={`${style.event} ${className}`}>
			{time && <div className={style.time}>{timeSlotUtcText(time.start, time.end)}</div>}
			<EventTitle event={event} linkTitle className={style.title} />
			<div className={style.excerpt}>{event.frontmatter.excerpt}</div>
			<div className={style.speakers}>
				{event.frontmatter.speakers.map(speaker => (
					<Link className={style.speaker} to={speaker.fields.path}>
						{speaker.frontmatter.name}
					</Link>
				))}
			</div>
			<div className={style.track}>
				<span>{trackText(event.frontmatter.track)}</span>
			</div>
		</div>
	)
}

const scheduleIntermission = event => {
	const time = event.start ? { start: event.start, end: event.end } : undefined
	return (
		<div id={event.title} className={`${style.event} ${style.intermission}`}>
			{time && <div className={style.time}>{timeSlotUtcText(time.start, time.end)}</div>}
			<span className={`${style.title} ${style.intermissionTitle}`}>
				{event.intermission.title}
			</span>
			<div className={style.excerpt}>
				<MarkdownAsHtml>{event.intermission.description}</MarkdownAsHtml>
			</div>
			<div className={style.track}>
				<span>{event.intermission.track ? event.intermission.track : "center stage"}</span>
			</div>
		</div>
	)
}

export const query = graphql`
	query {
		talks: allMarkdownRemark(filter: { fields: { collection: { eq: "talks" } } }) {
			nodes {
				fields {
					path
				}
				frontmatter {
					title
					excerpt
					track
					slug
					speakers {
						fields {
							path
						}
						frontmatter {
							name
						}
					}
				}
			}
		}
		interactives: allMarkdownRemark(
			filter: { fields: { collection: { in: ["panels", "qa", "ignite-talks"] } } }
		) {
			nodes {
				fields {
					path
				}
				frontmatter {
					title
					excerpt
					track
					slug
					speakers {
						fields {
							path
						}
						frontmatter {
							name
						}
					}
				}
			}
		}
	}
`

export default SchedulePage
